import { useState, useEffect, useCallback, useRef } from "react";
// libraries
import { useSnackbar } from "notistack";
import { Link, useLocation } from "react-router-dom";

// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
//hooks
import useAxios from "../hooks/useAxios";
// custom components
import AuthHOC from "../hoc/AuthHOC";
import BasicTable from "../components/BasicTable";
import UserDetail from "../components/user_details/UserDetail";
import CustomPagination from "../components/CustomPagination";
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urls from "../utils/urls.json";
import Consent from "../components/consent/Consent";
//columns for user calls info
const cols = [
  {
    name: "USERNAME",
    accessor: "user",
    getAccessor: (el) => el.first_name + " " + el.last_name,
  },
  {
    name: "CREDIT USED",
    accessor: "credit_used",
  },
  {
    name: "DATE",
    accessor: "created_on",
  },

  {
    name: "CALL STARTED",
    accessor: "time_call_started",
  },
  {
    name: "CALL ENDED",
    accessor: "time_call_ended",
  },

  {
    name: "TOTAL TIME",
    accessor: "root",
    getAccessor: (el) => {
      return el?.total_time
        ? `${el?.total_time?.split(":")[1]}:${
            el?.total_time?.split(":")[2]?.split(".")[0]
          } Mins`
        : "-";
    },
  },
  {
    name: "SERVICE USED",
    accessor: "service",
  },
];

const consentMessage = {
  active: {
    title: "Are you sure you want to deactivate the account",
    description:
      "If you move forward the account of this consumer will be deactivated and consumer will no longer be able to login again!",
  },
  not_active: {
    title: "Are you sure you want to activate the account",
    description:
      "If you move forward the account of this consumer will be activated and consumer will be able to login again!",
  },
};

function UserDetails({ user }) {
  const location = useLocation();
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const [loading, setLoading] = useState(true);
  const PAGE_SIZE = 10;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  // const [reload, setReload] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [userEnabled, setUserEnabled] = useState(location?.state?.is_active);
  const [openDialog, setOpenDialog] = useState(false);

  const deactivateUser = useCallback(async () => {
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/accounts/supervisor/consumers/`,
        data: {
          consumer_ids: [location?.state?.id],
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      setUserEnabled((prev) => !prev);
      notificationsHandler(
        "success",
        `${
          response?.data?.detail
            ? response?.data?.detail
            : "Request Successfull"
        }`,
        enqueueSnackbar,
        closeSnackbar
      );
      setOpenDialog(false);
    } catch (error) {
      if (error.message !== "canceled") {
        setOpenDialog(false);
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [closeSnackbar, enqueueSnackbar, location.state]);
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoading(true);

      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/calls`,
          params: {
            user_email: location.state ? location.state.email : "",
            page: page,
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setData(response.data.results);
        setCount(response.data.count);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
      setLoading(false);
    })();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  //breadcrumbs
  const breadcrumbs = [
    <Link key="1" to={urls.users} style={{ color: "#969696" }}>
      Standard Users
    </Link>,
    <Typography key="2" color="#969696">
      User Details
    </Typography>,
    <Typography key="3" color="#969696">
      User Call History
    </Typography>,
  ];

  //for user info
  const userData = [
    [
      {
        "First Name": () => {
          return location.state.first_name;
        },
      },
    ],
    [
      {
        "Last Name": () => {
          return location.state.last_name;
        },
      },
    ],

    [
      {
        Email: location.state.email,
      },
      {
        typographyStyle: { valueStyle: {} },
      },
    ],
    [
      {
        "Added ON": location.state.date_joined,
      },
    ],
  ];

  return (
    <>
      <Grid container spacing={3} sx={{ height: "100%" }}>
        {/* START: Breadcrumb */}
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        {/* END: Breadcrumb */}

        {/* START: Body Row */}
        <Grid item xs={12}>
          <UserDetail
            title="User Details"
            userEnabled={userEnabled}
            setUserEnabled={setUserEnabled}
            data={userData}
            actionTitle={userEnabled ? "Deactivate" : "Activate"}
            actionCallback={() => {
              deactivateUser();
            }}
            actionStyles={
              userEnabled
                ? {
                    background: "#EAEAEA",
                    color: "#969696",
                    ":hover": {
                      background: "#d1d1d1",
                    },
                  }
                : {
                    background: "rgba(71, 215, 162, 0.1);",
                    color: "#00B448",
                    ":hover": {
                      background: "rgba(34, 102, 77, 0.1);",
                    },
                  }
            }
            open={openDialog}
            setOpen={setOpenDialog}
            consent={
              <Consent
                open={openDialog}
                setOpen={setOpenDialog}
                title={
                  consentMessage[userEnabled ? "active" : "not_active"].title
                }
                description={
                  consentMessage[userEnabled ? "active" : "not_active"]
                    .description
                }
                acceptButtonTitle={userEnabled ? "Deactivate" : "Activate"}
                rejectButtonTitle={"Close"}
                handleSuccess={() => {
                  deactivateUser();
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sx={{}}>
          <Paper elevation={0} sx={{}}>
            <BasicTable
              cols={cols}
              rows={data ? data : []}
              loading={loading}
              title="Call History"
              sx={{ marginBottom: "1rem" }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <CustomPagination
            disabled={loading}
            last_page_no={Math.ceil(count / PAGE_SIZE)}
            limit={(data?.results && data?.results.length) || 0}
            handlePaginationChange={(_, value) => setPage(value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AuthHOC(UserDetails);
