// libraries
import { useEffect, useState, useCallback, useRef } from "react";

import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
// material UI styles
import { alpha } from "@mui/material/styles";

// material UI components
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";

import { Stack } from "@mui/material";
// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DoneIcon from "@mui/icons-material/Done";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

// custom
import AuthHOC from "../hoc/AuthHOC";
import BasicTable from "../components/BasicTable";
import AddCreditRequestForm from "../components/forms/addCreditRequestForm";
import CustomPagination from "../components/CustomPagination";

// utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
//hooks
import useAxios from "../hooks/useAxios";

const cols = [
  {
    name: "REQUEST ID",
    accessor: "id",
  },
  {
    name: "CREDIT REQUESTED",
    accessor: "credit",
    getAccessor: (el) => el.added,
  },
  {
    name: "PURPOSE",
    accessor: "credit",
    getAccessor: (el) => el.purpose,
  },
  {
    name: "REQUESTED ON",
    accessor: "created_on",
  },
  {
    name: "PAYMENT METHOD",
    accessor: "credit",
    getAccessor: (el) => el.payment.mode.toString(),
  },
  {
    name: "STATUS",
    accessor: "status",
    fancy: true,
  },
];

function CreditRequests({ user }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [fetchingRequests, setFetchingRequests] = useState(true);
  // const [loadingData, setLoadingData] = useState(true);

  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const PAGE_SIZE = 10;

  const fetchRequests = useCallback(
    async (abortController) => {
      setError(null);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/request/`,
          params: {
            page: page,
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setData(response.data);
      } catch (error) {
        // localStorage.setItem("error_p", JSON.stringify(true));
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        setError(true);
      }
      setLoading(false);
    },
    [page, enqueueSnackbar, closeSnackbar]
  );

  useEffect(() => {
    const abortController = new AbortController();
    setFetchingRequests(true);
    fetchRequests(abortController).then(() => setFetchingRequests(false));

    return () => abortController.abort();
  }, [page, fetchRequests]);

  const breadcrumbs = [
    <Link key="1" to="/credits/">
      Credits
    </Link>,
    <Typography key="2" color="text.primary">
      Manage Credits{" "}
    </Typography>,
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createRequest = async (added, paymentMode, purpose) => {
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/credits/request/`,
        data: { added, purpose, payment_mode: paymentMode },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      setData((prevState) => {
        return { ...prevState, results: [...prevState.results, response.data] };
      });
      notificationsHandler(
        "success",
        "Credit Request Sent",
        enqueueSnackbar,
        closeSnackbar,
        "Credit request has been sent successfully. Waiting for approval"
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* START: Breadcrumb */}
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        {/* END: Breadcrumb */}
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Paper elevation={0} style={{ padding: "24px 16px 24px 16px" }}>
                <Stack direction="row">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={4}
                  >
                    {/* START: Requests Approved */}
                    <Stack
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Stack>
                          <Avatar
                            variant="square"
                            style={{
                              backgroundColor: alpha("#47D7A2", 0.1),

                              borderRadius: "9px",
                            }}
                          >
                            <DoneIcon
                              style={{
                                color: "#47D7A2",
                                height: "28px",
                                width: "16px",
                              }}
                            />
                          </Avatar>
                        </Stack>
                        <Stack direction="column">
                          <Stack>
                            <Typography
                              style={{
                                color: "#022539",
                              }}
                            >
                              <b>
                                {data.results &&
                                  data.results.filter(
                                    (el) => el.status === "ACCEPTED"
                                  ).length}
                              </b>
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              style={{
                                color: "#666666",
                                display: "inline",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Requests Approved
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    {/* END: Requests Approved */}

                    {/* START: Requests Pending  */}

                    <Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Stack>
                          <Avatar
                            variant="square"
                            style={{
                              backgroundColor: alpha("#FB6281", 0.1),

                              borderRadius: "9px",
                            }}
                          >
                            <HourglassEmptyIcon style={{ color: "#FB6281" }} />
                          </Avatar>
                        </Stack>
                        <Stack direction="column">
                          <Stack>
                            <Typography
                              style={{
                                color: "#022539",
                              }}
                            >
                              <b>
                                {data.results &&
                                  data.results.filter(
                                    (el) => el.status === "SENT"
                                  ).length}
                              </b>
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              style={{
                                color: "#666666",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Requests Pending
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    {/* START: Request Cancelled */}
                    <Stack
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Stack>
                          <Avatar
                            variant="square"
                            style={{
                              backgroundColor: "#EAEAEA",

                              borderRadius: "9px",
                            }}
                          >
                            <DeleteOutlineOutlinedIcon
                              style={{ color: "#666666" }}
                            />
                          </Avatar>
                        </Stack>
                        <Stack direction="column" sx={{ width: "100%" }}>
                          <Stack>
                            <Typography
                              style={{
                                color: "#022539",
                              }}
                            >
                              <b>
                                {data.results &&
                                  data.results.filter(
                                    (el) => el.status === "REJECTED"
                                  ).length}
                              </b>
                            </Typography>
                          </Stack>
                          <Stack sx={{ width: "100%" }}>
                            <Typography
                              style={{
                                color: "#807D7D",
                              }}
                            >
                              Cancelled Request{" "}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
              {/* END:  Requests Pending*/}
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  sx={{
                    height: "62px",
                    // width: "222px",
                    backgroundColor: "#2F7EC7",
                    borderRadius: "4px",
                    float: "right",
                    clear: "right",
                    padding: {
                      md: "20px 8px 20px 8px",
                      xl: "20px 24px 20px 24px",
                    },
                    textTransform: "none",
                    ":hover": {
                      background: "#2a72b5",
                    },
                  }}
                  size="small"
                  disableElevation
                >
                  Request More Credits
                </Button>

                <Dialog onClose={handleClose} open={open} fullWidth>
                  <AddCreditRequestForm
                    handleClose={handleClose}
                    createRequest={createRequest}
                  />
                </Dialog>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={6}>
          <Paper elevation={0}>
            <BasicTable
              cols={cols}
              rows={data.results || []}
              loading={loading}
              linkKey="id"
              linkTo="/credits/requests"
            />
          </Paper>
          <CustomPagination
            disabled={fetchingRequests}
            last_page_no={Math.ceil(data.count / PAGE_SIZE)}
            limit={(data.results && data.results.length) || 0}
            handlePaginationChange={(_, value) => setPage(value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AuthHOC(CreditRequests);
