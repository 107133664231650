import { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import withStyles from "@mui/styles/withStyles";
import { Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
const DialogContent = withStyles((theme) => ({
  root: {
    padding: "20px 15px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "0px 15px",
  },
}))(MuiDialogActions);

const CustomTextField = styled(TextField, {
  name: "CustomTextField",
  slot: "Root",
  lable: "CustomTextField",
})(({ theme }) => ({
  background: "#F6F6F6",
  border: "1px solid #E0E0E0",
  borderRadius: "5px",
  width: "100%",
  marginTop: "12px",
  marginBottom: "16px",
}));
const CustomTypography = styled(Typography, {
  name: "CustomTypography",
  slot: "Root",
  lable: "CustomTypography",
})(({ theme }) => ({
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "21px",
  color: "#0f0f0f",
  textAlign: "left",
  display: "block",
}));
export default function AddConsumerForm(props) {
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmail("");
    setPassword1("");
    setFirstName("");
    setLastName("");
    props.createConsumer(email, password1, firstName, lastName);
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: "100%",
        paddingBottom: "40px",
        padding: "0 15px 40px 15px",
      }}
      align="center"
    >
      <DialogTitle onClose={props.handleClose}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Typography
                component="div"
                variant="body2"
                style={{
                  width: "100%",
                  color: "#022539",
                }}
                align="center"
              >
                <Box
                  fontWeight={500}
                  fontFamily="DM Sans"
                  fontStyle="normal"
                  fontSize="18px"
                >
                  Add New User
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <IconButton onClick={props.handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Box>
            <CustomTypography component="span">First Name*</CustomTypography>
            <CustomTextField
              variant="outlined"
              size="small"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
          </Box>
          <Box>
            <CustomTypography component="span">Last Name*</CustomTypography>
            <CustomTextField
              variant="outlined"
              size="small"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              required
            />
          </Box>
          <Box>
            <CustomTypography component="span">Email Id *</CustomTypography>
            <CustomTextField
              variant="outlined"
              size="small"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </Box>
          <Box>
            <CustomTypography component="span">Password*</CustomTypography>
            <CustomTextField
              variant="outlined"
              size="small"
              type="password"
              value={password1}
              onChange={(event) => setPassword1(event.target.value)}
              required
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            variant="text"
            disableElevation
            sx={{
              padding: "12px 12px",
            }}
            onClick={() => {
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={props.adding}
            disableElevation
            sx={{
              padding: "12px 24px",
              backgroundColor: !props.adding && "#2F7EC7",
            }}
          >
            Add User
          </Button>
        </Stack>
        {/* <Button
          variant="contained"
          type="submit"
          disabled={props.adding}
          sx={{
            margin: "auto",
            height: "34px",
            width: "184px",
            marginBottom: "30px",
            backgroundColor: !props.adding && "#2CA9E3",
          }}
          disableElevation
        >
          <Typography
            style={{
              color: "#FFFFFF",
            }}
            component="div"
          >
            <Box
              fontWeight={400}
              fontFamily="DM Sans"
              fontStyle="normal"
              fontSize="14px"
            >
              ADD USER
            </Box>
          </Typography>
        </Button> */}
      </DialogActions>
    </form>
  );
}

AddConsumerForm.propTypes = {
  createConsumer: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};
