// libraries
import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// material UI styles
import withStyles from "@mui/styles/withStyles";

// material UI components
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
// import MuiDialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// material UI icons
import { Stack } from "@mui/material";
import { axiosConfig } from "../../utils/helpers";
import urls from "../../utils/urls.json";
// import Cheque from "../../assets/cheque.png"
// import Stripe from "../../assets/stripe.png"
//hooks
import useAxios from "../../hooks/useAxios";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function EditCreditRequestForm(props) {
  const [added, setAdded] = useState("");
  const [paymentMode, setPaymentMode] = useState("STRIPE");
  const [purpose, setPurpose] = useState("");
  const [, setData] = useState("");
  const [, setError] = useState("");
  const history = useHistory();
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  //callback for handling form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/credits/request/`,
        data: {
          creditrequest_id: props.location.state.id,
          payment_mode: paymentMode,
          added,
          purpose,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      setData((prevState) => [...prevState, response.data]);
      history.push(urls.credit_requests);
    } catch (error) {
      if (error.request) {
        setError(JSON.parse(error.request.response));
      } else {
        setError(error);
      }
    }
    props.handleClose();
  };
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }} align="center">
      <DialogTitle onClose={props.handleClose}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Toolbar>
                <Box
                  fontWeight={700}
                  fontFamily="DM Sans"
                  fontStyle="normal"
                  width="100%"
                >
                  <Typography
                    style={{
                      color: "#022539",
                      fontWeight: "500",
                      fontSize: "24px",
                    }}
                    align="center"
                  >
                    Edit credit request
                  </Typography>
                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" paddingLeft="40px" spacing={4}>
          <Stack direction="column" spacing={2}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
              }}
              textAlign="left"
            >
              Number of credits
            </Typography>

            <TextField
              type="number"
              style={{ width: "95%" }}
              placeholder="Enter the number of credits"
              InputProps={{ disableUnderline: true }}
              variant="filled"
              size="small"
              value={added}
              onChange={(event) => setAdded(event.target.value)}
              sx={{
                ".MuiFilledInput-input": {
                  py: "14px",
                },
              }}
              required
            />
          </Stack>

          <Stack direction="column" spacing={2}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
              }}
              textAlign="left"
            >
              Purpose
            </Typography>

            <TextField
              type="text"
              style={{ width: "95%" }}
              placeholder="Eg: Research, Clinical Trial, etc."
              InputProps={{ disableUnderline: true }}
              variant="filled"
              size="small"
              value={purpose}
              onChange={(event) => setPurpose(event.target.value)}
              sx={{
                ".MuiFilledInput-input": {
                  py: "14px",
                },
              }}
            />
          </Stack>

          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
              }}
              textAlign="left"
            >
              Mode of Payment
            </Typography>
            <FormControl
              component="fieldset"
              style={{
                width: "85%",
                color: "#0F0F0F",
              }}
              required
            >
              <RadioGroup
                aria-label="payment-method"
                name="payment-method"
                value={paymentMode}
                onChange={(event) => setPaymentMode(event.target.value)}
              >
                <FormControlLabel
                  value="STRIPE"
                  control={<Radio style={{ color: "#000000" }} />}
                  label="Stripe"
                />

                <FormControlLabel
                  value="CHEQUE"
                  control={<Radio style={{ color: "#000000" }} />}
                  label="Cheque(offline)"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="space-between" width="95%">
            <Typography
              variant="button"
              cursor="pointer"
              type="submit"
              style={{
                height: "34px",
                marginBottom: "30px",
                color: "#959595",
                cursor: "pointer",
              }}
              onClick={props.handleClose}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              type="submit"
              style={{
                height: "34px",
                marginBottom: "30px",
                backgroundColor: "#2F7EC7",
                textTransform: "none",
              }}
              disableElevation
            >
              Edit Request
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </form>
  );
}

EditCreditRequestForm.propTypes = {
  handleClose: PropTypes.func,
};
