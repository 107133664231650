// libraries
import axios from "axios";

// material UI icons
import { Remove } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//internal component
import Status from "../components/status/Status";
import CustomNotification from "../components/notification/CustomNotification";

export const DEBUG = process.env.NODE_ENV === "development";
// constants
export const API_URL = DEBUG
  ? process.env.REACT_APP_DEV_API_URL
  : process.env.REACT_APP_PROD_API_URL;

// helpers for storing and retrieving user info
// generate axios params
export const axiosConfig = ({
  method,
  uri = null,
  url = null,
  data = null,
  params = {},
  headers = {},

  ...others
}) => {
  if (!uri && !url) {
    throw Error("You Must provide url or uri to proceed ");
  }
  let reqUrl = url
    ? url
    : uri.startsWith("/")
    ? `${API_URL}${uri}`
    : `${API_URL}/${uri}`;

  let options = {
    method,
    url: reqUrl,
    data,
    params: { ...params },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    ...others,
  };

  // if (DEBUG) {
  //   const accessToken = new HandleAuthToken().retrieveToken();
  //   console.log(accessToken, "axios config");
  //   if (accessToken)
  //     options["headers"]["Authorization"] = `Bearer ${accessToken}`;
  //   return options;
  // }
  // options["withCredentials"] = true;
  return options;
};

// helpers for storing and retrieving user info
export class HandleLocalStorage {
  add = (key, value) => {
    localStorage.setItem(key, value);
    return value;
  };

  retrieve = (key) => {
    return localStorage.getItem(key);
  };

  clear = (key) => {
    const value = this.retrieve(key);
    localStorage.removeItem(key);
    return value;
  };
}

export class HandleAuthToken extends HandleLocalStorage {
  #USER = "user";
  #TOKEN = "nb-score-access-token";
  #REFRESH_TOKEN = "nb-score-refresh-token";
  #TOKEN_EXPIRE = "nb-score-access-token-expiration";
  #REFRESH_TOKEN_EXPIRE = "nb-score-refresh-token-expiration";

  addUser = (user) => this.add(this.#USER, JSON.stringify(user));
  clearUser = () => this.clear(this.#USER);
  retrieveUser = () => JSON.parse(this.retrieve(this.#USER));

  addToken = (token) => this.add(this.#TOKEN, token);
  clearToken = () => this.clear(this.#TOKEN);
  retrieveToken = () => this.retrieve(this.#TOKEN);

  addTokenExp = (expiration) => this.add(this.#TOKEN_EXPIRE, expiration);
  clearTokenExp = () => this.clear(this.#TOKEN_EXPIRE);
  retrieveTokenExp = () => this.retrieve(this.#TOKEN_EXPIRE);

  addRefreshToken = (token) => this.add(this.#REFRESH_TOKEN, token);
  clearRefreshToken = () => this.clear(this.#REFRESH_TOKEN);
  retrieveRefreshToken = () => this.retrieve(this.#REFRESH_TOKEN);

  addRefreshTokenExp = (expiration) =>
    this.add(this.#REFRESH_TOKEN_EXPIRE, expiration);
  clearRefreshTokenExp = () => this.clear(this.#REFRESH_TOKEN_EXPIRE);
  retrieveRefreshTokenExp = () => this.retrieve(this.#REFRESH_TOKEN_EXPIRE);

  //adding the data to local storage if debug=true (development)
  addData = (user, accessToken = null, refreshToken = null) => {
    this.addUser(user);
    if (accessToken) {
      if (DEBUG) this.addToken(accessToken.token);
      this.addTokenExp(accessToken.expiration);
    }
    if (refreshToken) {
      if (DEBUG) this.addRefreshToken(refreshToken.token);
      this.addRefreshTokenExp(refreshToken.expiration);
    }
  };

  clearData = () => {
    this.clearUser();
    this.clearTokenExp();
    this.clearRefreshTokenExp();
    this.clearToken();
    this.clearRefreshToken();
  };
}

export const setUser = (user) =>
  localStorage.setItem("user", JSON.stringify(user));
export const removeUser = () => localStorage.removeItem("user");
export const getUser = localStorage.getItem("user");

export async function loginSuccess(
  response,
  dispatch,
  enqueueSnackbar,
  closeSnackbar
) {
  try {
    const config = axiosConfig({
      method: "POST",
      uri: `/auth/registration/google/`,
      data: {
        access_token: response.tokenObj.access_token,
        id_token: response.tokenObj.id_token,
      },
    });
    await axios({ ...config });
    dispatch({
      type: "signin",
      payload: {
        user: response.data.user,
        accessToken: {
          token: response.data.access_token,
          expiration: response.data.access_token_expiration,
        },
        refreshToken: {
          token: response.data.refresh_token,
          expiration: response.data.refresh_token_expiration,
        },
      },
      error: null,
    });
  } catch (error) {
    httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
  }
}
export function loginFailed(response, enqueueSnackbar, closeSnackbar) {
  httpErrorHandler(response, enqueueSnackbar, closeSnackbar);
}

export const cellData = (cell, status) => {
  function isValidDate(dateString) {
    //accepted format - 2018-08-01T18:30:00.000Z
    const _regExp = new RegExp(
      "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
    );

    return _regExp.test(dateString);
  }

  if (typeof cell === "boolean") {
    return cell ? (
      <Box
        sx={{
          height: "30px",
          ...{ backgroundColor: "#E0FFDF", color: "#10A44B;" },
          borderRadius: "5px",
          maxWidth: "150px",
          py: "8px",
          px: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography component="span">Active</Typography>
      </Box>
    ) : (
      <Box
        sx={{
          height: "30px",
          ...{ backgroundColor: "#FFF3F3", color: "#E03A3A" },
          borderRadius: "5px",
          maxWidth: "150px",
          py: "8px",
          px: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography component="span">Not Active</Typography>
      </Box>
    );
  }
  if (typeof cell === "number") {
    return cell;
  }
  if (cell && !isNaN(cell) && cell.toString().indexOf(".") !== -1) {
    return parseFloat(cell).toFixed(0);
  }
  if (cell === null || cell === "") {
    return <Remove />;
  }
  if (isValidDate(cell)) {
    return new Date(cell).toDateString();
  }
  return status ? <Status status={cell} /> : cell;
};

export function genPageTitle(pathname) {
  if (pathname === "/") return "Dashboard";
  if (pathname.includes("/credit/")) return "Credits";
  if (pathname === "/credits/") return "Credits";

  if (pathname.includes("standard-user/")) return "Standard Users";
  if (pathname.includes("/call")) return "Call History";
  if (pathname.includes("/credits/requests/")) return "Manage Credits";

  return pathname
    .split("/")
    .filter((el) => el !== "")
    .join(" ")
    .replace(/credits/g, "credit")
    .replace(/-/g, " ");
}

export const logout = async (refreshToken, notifyCtx, navigate) => {
  try {
    const config = axiosConfig({
      method: "POST",
      uri: `/auth/logout/`,
    });
    if (DEBUG) {
      config["data"] = { refresh: refreshToken["refreshToken"] };
    }
    await axios({ ...config, withCredentials: true });
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
  }
};

export function notificationsHandler(
  severity,
  title,
  enqueueSnackbar,
  closeSnackbar,
  message = "",
  duration = 3000
) {
  enqueueSnackbar(title, {
    autoHideDuration: duration ? duration : 3000,
    preventDuplicate: true,
    content: (key, title) => (
      <CustomNotification
        id={key}
        title={title}
        variant={severity}
        message={message}
        close={closeSnackbar}
      />
    ),
  });
}

export function httpErrorHandler(
  error,
  enqueueSnackbar,
  closeSnackbar,
  customErrorMessage = []
) {
  localStorage.setItem("error_p", JSON.stringify(true));
  //this function handles the custom error messages if sent
  function customErrorMessageHandler(customErrorMessage, status) {
    return customErrorMessage.find((element, index) => {
      return status === element.status;
    });
  }

  try {
    if (error.message === "canceled") {
      return;
    }
    if (error.response) {
      if (error.response.status >= 501) {
        window.location.assign(`/error/${error.response.status}`);
      } else if (
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        window.location.assign(`/error/${error.response.status}`);
      } else {
        if (error.response.data.__proto__ !== Object.prototype) {
          //if data is not object
          notificationsHandler(
            "error",
            "Error Occurred",
            enqueueSnackbar,
            closeSnackbar,
            error.message,
            3000
          );
          return;
        } else if ("detail" in error.response.data) {
          //details in response for google login error handling in signin page of supervisor
          const message = customErrorMessageHandler(
            customErrorMessage,
            error.response.status
          );
          notificationsHandler(
            "error",
            "Error Occurred",
            enqueueSnackbar,
            closeSnackbar,
            `${
              customErrorMessage.length
                ? message !== undefined
                  ? message.message
                  : error.response.data.detail
                : error.response.data.detail
            }`,
            3000
          );
          return;
        } else {
          const message = customErrorMessageHandler(
            customErrorMessage,
            error.response.status
          );
          for (let key in error.response.data) {
            notificationsHandler(
              "error",
              "Error Occurred",

              enqueueSnackbar,
              closeSnackbar,
              `${
                customErrorMessage.length
                  ? message !== undefined
                    ? message.message
                    : error.response.data[key]
                  : error.response.data[key]
              }`,
              3000
            );
          }
        }
      }
    } else if (error.request) {
      notificationsHandler(
        "error",
        "Error Occurred",
        enqueueSnackbar,
        closeSnackbar,
        `${
          customErrorMessage.length
            ? customErrorMessageHandler(
                customErrorMessage,
                error.request.status
              ).message
            : error.message
        }`,
        3000
      );
    } else {
      //handles any error other that error request,respose
      if ("details" in error) {
        notificationsHandler(
          "error",
          "Error Occurred",
          enqueueSnackbar,
          closeSnackbar,
          `${error.details}`,
          3000
        );
      } else {
        notificationsHandler(
          "error",
          "Error Occurred",
          enqueueSnackbar,
          closeSnackbar,
          `${error.message}`,
          3000
        );
      }
    }
  } catch (error) {
    //handles any other error if comes in handling error
    notificationsHandler(
      "error",
      "Error Occurred",
      enqueueSnackbar,
      closeSnackbar,
      `Something Went Wrong! ${error.message}`,
      3000
    );
  }
}
