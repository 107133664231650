import Credits from "../Credits";
// import CopyrightIcon from "@mui/icons-material/Copyright";

// import Avatar from "@mui/material/Avatar";
// import { PersonOutlined } from "@mui/icons-material";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { Remove } from "@mui/icons-material";
import { Paper } from "@mui/material";
import { useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { axiosConfig, httpErrorHandler } from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//internal
import ComponentLoader from "../loader/ComponentLoader";
//3rd party
import { useSnackbar } from "notistack";
export default function CreditConsumed({
  vertical = false,
  action_text = null,
  action_link = null,
}) {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [usage, setUsage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);

  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoading(true);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setUsage((prevState) => {
          let totalAdded = 0;
          let totalUsed = 0;
          response.data.results.forEach((credit) => {
            totalAdded += parseFloat(credit.added);
            totalUsed += parseFloat(credit.used);
          });
          // let percentage = Math.floor((totalUsed / totalAdded) * 100);
          let percentage = (totalUsed / totalAdded) * 100;
          return percentage ? parseFloat(percentage).toFixed(2) : 0;
        });
        setLoading(false);
      } catch (error) {
        if (error.message !== "canceled") {
          setLoading(false);
          setError(error);
        }
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [reload, closeSnackbar, enqueueSnackbar]);

  return (
    <>
      {/* START: Credits Used  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "26px 37px",
          }}
        >
          <Stack flex={true} justifyContent="space-between" alignItems="center">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={vertical ? 12 : 5}
                  // sx={{
                  //   paddingLeft: vertical ? "initial" : "0px !important",
                  // }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent={vertical ? "center" : "initial"}
                    >
                      <Credits usage={usage ? parseFloat(usage) : 0} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={vertical ? 12 : 7}>
                  {loading || error ? (
                    <ComponentLoader
                      loading={loading}
                      error={error}
                      message=""
                      sx={{
                        background: "transparent",
                        minHeight: "100%",
                      }}
                      retry={() => {
                        setReload((prev) => {
                          return !prev;
                        });
                      }}
                    />
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: vertical ? "100%" : "auto",
                        }}
                      >
                        <Typography
                          style={{
                            color: theme.palette.primary.main,
                          }}
                          variant="h4"
                          align={vertical ? "center" : "left"}
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: "calc(10.7px + 1vw)",
                            lineHeight: "48px",
                            fontWeight: "500",
                          }}
                        >
                          {loading ? <Remove /> : `${usage}%`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: vertical ? "100%" : "auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "18px",
                            lineHeight: "27px",
                            color: "#AAAAAA",
                          }}
                          variant="body2"
                          align={vertical ? "center" : "left"}
                        >
                          Credits Used
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {action_text ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  padding: "25px 0px 0px 0px",
                }}
                component={Link}
                to={action_link ? action_link : "#"}
              >
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  sx={{
                    height: "42px",
                    width: "245px",
                    backgroundColor: "#2F7EC7",
                    ":hover": {
                      backgroundColor: "#2a72b5",
                    },
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                >
                  {action_text}
                </Button>
              </Grid>
            ) : null}
          </Stack>
        </Paper>
      </Grid>
      {/* END: Credits Used */}
    </>
  );
}
