// libraries
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// material UI styles
import makeStyles from "@mui/styles/makeStyles";

// material UI components
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
// utils
import { cellData } from "../utils/helpers";
//3rd party
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles({
  table: {},
  text: {},

  active: {
    fontSize: 14,
    color: "#1A1A1A",
  },
  inactive: {
    fontSize: 14,
    color: "#BBBBBB",
  },
  page: {
    color: "red",
  },
});

export default function BasicTable({
  title,
  cols,
  rows,
  loading,
  paging,
  linkKey,
  linkTo,
  viewAll,
  viewTo,
  sx,
  useStatus = true, //if status in cols use status component
}) {
  const classes = useStyles();
  const history = useHistory();
  const redirect = Boolean(linkKey && linkTo);

  const LinkedTableRow = (props) => {
    const handleClick = (e) => {
      if (redirect) {
        history.push(linkTo + "/" + props.row[linkKey], props.row);
      }
    };

    return (
      <TableRow
        onClick={handleClick}
        sx={{
          cursor: redirect ? "pointer" : "unset",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {cols.map((col, i) => {
          return (
            <TableCell
              key={i}
              scope="row"
              sx={{
                height: "clamp(63px, 65px, 100px)",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "21px",

                color: "#0F0F0F",
              }}
            >
              {col.getAccessor
                ? cellData(
                    col.accessor === "root"
                      ? col.getAccessor(props.row)
                      : col.getAccessor(props.row[col.accessor])
                  )
                : col.name === "STATUS"
                ? cellData(props.row[col.accessor], useStatus)
                : cellData(props.row[col.accessor])}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        // boxShadow: "0px 5.06667px 29.3867px rgba(112, 112, 112, 0.06)",
        borderRadius: "8px",
      }}
    >
      {title ? (
        <Toolbar sx={{ backgroundColor: "#ffffff" }}>
          <Typography
            variant="h6"
            style={{
              padding: 0,
              margin: 0,
              marginBottom: "-10px",
              color: "#022539",
            }}
          >
            {title}
          </Typography>
        </Toolbar>
      ) : null}

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          ...sx,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead sx={{ backgroundColor: "#F0F0F0" }}>
            <TableRow>
              {cols.map((col, i) => (
                <TableCell
                  key={i}
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "#888888",
                  }}
                >
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={cols.length}
                  className={classes.tableCell}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#2F7EC7" }} />
                  ) : (
                    "No Entries Found"
                  )}
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => <LinkedTableRow key={index} row={row} />)
            )}
            {viewAll && rows.length ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={cols.length}
                  sx={{
                    cursor: "pointer",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "#2F7EC7",
                  }}
                >
                  <Link to={viewTo}>View All</Link>
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {paging ? (
        <Toolbar>
          <Pagination
            className={classes.page}
            count={8}
            size="medium"
            color="primary"
          />
        </Toolbar>
      ) : null}
    </Paper>
  );
}

BasicTable.propTypes = {
  title: PropTypes.string,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};
