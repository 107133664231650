import { useState } from "react";
import Button from "@mui/material/Button";
import logo from "../../assets/logo.svg";

import { Container, Paper, Typography, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";

export default function ForgotPasswordForm({ handleSubmit, loading }) {
  const [email, setEmail] = useState("");

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#E5E5E5",
        height: "100vh",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "700px",
            padding: "64px 64px  263px 64px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              py={5}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Forgot Password
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "26.04px",
                  letterSpacing: "0em",
                  color: "#8A8A8A",
                }}
              >
                Please enter your email address that you used to register. We
                will send a password reset link shortly.
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <form
                  method="post"
                  onSubmit={(event) => handleSubmit(event, email)}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Email address
                  </Typography>
                  <TextField
                    inputProps={{
                      style: {
                        padding: "15px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                        fontFamily: "DM Sans",
                      },
                    }}
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#FBFBFB",
                      borderColor: "1px solid #C4C4C4",
                      width: "100%",
                      maxWidth: "700px",
                    }}
                  />
                </form>
              </Stack>

              <Button
                disabled={loading}
                variant="contained"
                sx={{
                  backgroundColor: "#2F7EC7",
                  padding: "15px",
                  borderRadius: "5px",
                  width: "100%",
                  maxWidth: "700px",
                }}
                onClick={(e) => {
                  handleSubmit(e, email);
                }}
                type="submit"
              >
                Send
              </Button>
            </Stack>
            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                  textAlign: "center",
                  lineHeight: "0.1em",
                  width: "100%",
                  marginTop: "72px",
                }}
              >
                If you need any help, contact{" "}
                <font color="#2F7EC7">Neurobit PSG Support</font>
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
