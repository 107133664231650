import { useState } from "react";

// import PropTypes from "prop-types";
import { Stack, Paper, TextField, Button } from "@mui/material";
// CONTEXT
// import { globalContext } from "../../context/GlobalContext";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

export default function UpdateNameForm(props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState({
    first_name: props.firstName ? props.firstName : "",
    last_name: props.lastName ? props.lastName : "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          sx={{
            color: "#2F7EC7",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Edit
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <Paper
            sx={{
              width: "100%",
              // height: "457px",
            }}
          >
            {/* <DialogTitle
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#000000",
                my: "18px",
              }}
            >
              Update Password
            </DialogTitle> */}
            <DialogContent>
              <Stack direction="column" alignItems="flex-start" spacing={3}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <DialogContentText
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    First Name
                  </DialogContentText>
                  <TextField
                    inputProps={{
                      style: {
                        padding: "10px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                        fontFamily: "DM Sans",
                      },
                    }}
                    type="text"
                    name="first-name"
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#F6F6F6",
                      borderColor: "#E0E0E0",
                      width: "532px",
                    }}
                    value={name.first_name}
                    onChange={(e) => {
                      setName((prev) => {
                        return {
                          last_name: prev.last_name,
                          first_name: e.target.value,
                        };
                      });
                    }}
                  />
                </Stack>

                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <DialogContentText
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Last Name
                  </DialogContentText>
                  <TextField
                    inputProps={{
                      style: {
                        padding: "10px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                        fontFamily: "DM Sans",
                      },
                    }}
                    type="text"
                    name="last-name"
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#F6F6F6",
                      borderColor: "#E0E0E0",
                      width: "532px",
                    }}
                    value={name.last_name}
                    onChange={(e) => {
                      setName((prev) => {
                        return {
                          first_name: prev.first_name,
                          last_name: e.target.value,
                        };
                      });
                    }}
                  />
                </Stack>

                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Button
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#959595",
                      textTransform: "none",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#2F7EC7",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: 500,
                      textTransform: "none",
                      width: "104px",
                    }}
                    variant="filled"
                    type="submit"
                    disabled={props.dataLoading}
                    onClick={() => {
                      setOpen(false);
                      props.updateNameHandler(name.first_name, name.last_name);
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </DialogContent>
          </Paper>
        </Dialog>
      </div>
    </>
  );
}

// UpdatePasswordForm.propTypes = {
//   loading: PropTypes.bool.isRequired,
//   updatePassword: PropTypes.func.isRequired,
// };
