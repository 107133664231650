// libraries
import PropTypes from "prop-types";
// material UI styles
import { alpha } from "@mui/material/styles";
// material UI components
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
//assets
import CreditSvg from "../assets/dashboard/credits_rectangle.svg";

const CreditsIcon = (props) => {
  return (
    <Icon sx={{ width: "28px", height: "28px", ...props.sx }}>
      <img src={CreditSvg} alt="credits_icon" />
    </Icon>
  );
};
export default function Credits({ usage }) {
  // const theme = useTheme();

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => alpha(theme.palette.primary.light, 0.19),
        }}
        size={92}
        thickness={3.5}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.primary.light,
          position: "absolute",
          left: 0,
        }}
        value={usage}
        size={80}
        thickness={3.5}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CreditsIcon />
      </Box>
    </Box>
  );
}

Credits.propTypes = {
  usage: PropTypes.number.isRequired,
};
