// libraries
import { BrowserRouter, Route, Switch } from "react-router-dom";

// material UI styles
import { ThemeProvider } from "@mui/material/styles";
// import { StyledEngineProvider } from "@mui/material/styles";

// material UI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// custom components
import Error from "./components/helpers/error";
import SideBar from "./components/SideBar";
import ErrorPageHandler from "./components/error/ErrorPageHandler";

//theme
import { light } from "./utils/theme";

// custom hooks
import { AuthProvider } from "./context/authContext";
import useAuth from "./hooks/useAuth";
//context
import GlobalContextProvider from "./context/globalContext";
// custom pages
import Dashboard from "./pages/dashboard";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import VerifyEmail from "./pages/verifyEmail";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import Credits from "./pages/credits";
import creditRequestsDetails from "./pages/creditRequestsDetails";
import StandardUsers from "./pages/standardUsers";
import CallHistories from "./pages/callHistories";
import Profile from "./pages/profile";
import CreditDetails from "./pages/creditDetails";
import CreditRequests from "./pages/creditRequests";
import Checkout from "./pages/checkout";
import Success from "./pages/success";
import Cancel from "./pages/cancel";
import CallDetails from "./pages/callDetails";
import UserDetails from "./pages/userDetails";
// utils
import urlpatterns from "./utils/urls.json";
//3rd party
import { SnackbarProvider } from "notistack";
import Error4xx from "./components/error/Error4xx";

const Routes = () => {
  const [auth, dispatch] = useAuth();
  return (
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Switch>
          <Route
            path={[
              "/",
              `${urlpatterns.signin}`,
              `${urlpatterns.registration}`,
              `${urlpatterns.credits}`,
              `${urlpatterns.credit_requests}`,
              `${urlpatterns.credit_details}`,
              `${urlpatterns.credit_requests_details}`,
              `${urlpatterns.credit_requests_checkout}`,
              `${urlpatterns.credit_requests_payment_success}`,
              `${urlpatterns.credit_requests_payment_cancel}`,
              `${urlpatterns.call_histories}`,
              `${urlpatterns.call_details}`,
              `${urlpatterns.users}`,
              `${urlpatterns.users_details}`,
              `${urlpatterns.profile}`,
              `${urlpatterns.confirm_email}`,
              `${urlpatterns.forgot_password}`,
              `${urlpatterns.reset_password}`,
              `${urlpatterns.dashboard}`,
            ]}
            exact
          >
            <SideBar dispatch={dispatch} auth={auth}>
              <Switch>
                <Route path={urlpatterns.signin} component={SignIn} exact />
                <Route
                  path={urlpatterns.registration}
                  component={SignUp}
                  exact
                />
                <Route path={urlpatterns.credits} component={Credits} exact />
                <Route
                  path={urlpatterns.credit_requests}
                  component={CreditRequests}
                  exact
                />
                <Route
                  path={urlpatterns.credit_details}
                  component={CreditDetails}
                  exact
                />
                <Route
                  path={urlpatterns.credit_requests_details}
                  component={creditRequestsDetails}
                  exact
                />
                <Route
                  path={urlpatterns.credit_requests_checkout}
                  component={Checkout}
                  exact
                />
                <Route
                  path={urlpatterns.credit_requests_payment_success}
                  component={Success}
                  exact
                />
                <Route
                  path={urlpatterns.credit_requests_payment_cancel}
                  component={Cancel}
                  exact
                />
                <Route path={urlpatterns.profile} component={Profile} exact />
                <Route
                  path={urlpatterns.call_histories}
                  component={CallHistories}
                  exact
                />
                <Route
                  path={urlpatterns.call_details}
                  component={CallDetails}
                  exact
                />
                <Route
                  path={urlpatterns.users}
                  component={StandardUsers}
                  exact
                />
                <Route
                  path={urlpatterns.users_details}
                  component={UserDetails}
                  exact
                />
                <Route
                  path={urlpatterns.confirm_email}
                  component={VerifyEmail}
                  exact
                />
                <Route
                  path={urlpatterns.forgot_password}
                  component={ForgotPassword}
                  exact
                />
                <Route
                  path={urlpatterns.reset_password}
                  component={ResetPassword}
                  exact
                />
                <Route
                  path={urlpatterns.dashboard}
                  component={Dashboard}
                  exact
                />
              </Switch>
            </SideBar>
          </Route>
          <Route exact path="/error/:error" component={ErrorPageHandler} />
          <Route path="*">
            <Error4xx />
          </Route>
        </Switch>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

const Layout = () => {
  return (
    <>
      <Box p={5} sx={{ display: { xs: "block", lg: "none", xl: "none" } }}>
        <Typography variant="h5" align="center">
          Device not supported
        </Typography>
        <Typography variant="body1" align="center">
          This site can only be accessed on large devices
        </Typography>
      </Box>
      <Box
        elevation={0}
        sx={{ display: { xs: "none", lg: "block", xl: "block" } }}
      >
        <Box positon="absolute" height="100%"></Box>

        <Routes />
      </Box>
    </>
  );
};

export default function App() {
  return (
    <ThemeProvider theme={light}>
      {navigator.cookieEnabled ? (
        <GlobalContextProvider>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </GlobalContextProvider>
      ) : (
        <Error message="Cookies are not enabled! Please enable them, we use cookies for authentication" />
      )}
    </ThemeProvider>
  );
}
