// libraries
import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
// material UI components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
//hooks
import useAxios from "../hooks/useAxios";
// custom components
import AddConsumerForm from "../components/forms/addConsumerForm";
import BasicTable from "../components/BasicTable";
import AuthHOC from "../hoc/AuthHOC";
import ComponentLoader from "../components/loader/ComponentLoader";
//react
import { useRef } from "react";

// utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import SearchHeader from "../components/search_header/SearchHeader";
import CustomPagination from "../components/CustomPagination";

const cols = [
  {
    name: "NAME",
    accessor: "root",
    getAccessor: (el) => {
      return el.first_name + " " + el.last_name;
    },
  },
  {
    name: "EMAIL",
    accessor: "email",
  },
  {
    name: "DATE JOINED",
    accessor: "date_joined",
  },
  {
    name: "LAST LOGIN",
    accessor: "last_login",
  },
];
const filterByMenu = [
  {
    label: "Name",
    value: "first_name",
  },
  {
    label: "Email",
    value: "email",
  },
];
const inititalState = {
  clients: {
    count: 0,
    results: [],
  },
};
const checkSearchKey = (searchKey) => {
  return Object.values(searchKey).every((value) => {
    if (value === null) {
      return true;
    }
    return false;
  });
};

function StandardUsers({ user }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [, setClients] = useState([]);
  const [adding, setAdding] = useState(false);
  const [searchKey, setSearchKey] = useState({
    first_name: null,
    email: null,
    created_on_from: null,
    created_on_to: null,
    credit_request_status: null,
  });
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const paginationCall = useRef(false);
  const [reload, setReload] = useState(null);
  const [error, setError] = useState(null);
  const [searching, setSearching] = useState(false); //represent searching is happening
  const [data, setData] = useState(inititalState); //contains superviors data
  const [page, setPage] = useState(1);
  const [loadingData, setLoadingData] = useState(true); //show fetchUsers request sent. for basic table

  const [open, setOpen] = useState(false);
  const PAGE_SIZE = 10;

  const fetchUsers = useCallback(
    async ({ abortController, searchParams, pageValue = null }) => {
      setError(null);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/accounts/supervisor/consumers/`,

          params: {
            page: pageValue ? pageValue : page,
            page_size: PAGE_SIZE, // TODO: CHANGE THIS
            ...searchParams,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setData({
          clients: {
            results: response.data.results,
            count: response.data.count,
          },
        });
        paginationCall.current = false;
        return null;
      } catch (error) {
        if (error.message !== "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        paginationCall.current = false;
        return error;
      }
    },
    [page, enqueueSnackbar, closeSnackbar]
  );
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      if (checkSearchKey(searchKey) && !paginationCall.current) {
        setLoadingData(true);
        const error = await fetchUsers({
          abortController,
          searchParams: searchKey,
        });
        if (!error || (error && error?.message !== "canceled")) {
          setLoadingData(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => abortController.abort();
  }, [reload, searchKey, fetchUsers]); //don't add paginationCall dependency

  useEffect(() => {
    // console.log("useEffect triggered", searchKey, paginationCall.current, page);
    const abortController = new AbortController();
    //search key
    (async () => {
      if (!checkSearchKey(searchKey) && !paginationCall.current) {
        setPage(1);
        setSearching(true);
        const error = await fetchUsers({
          abortController,
          searchParams: searchKey,
          pageValue: 1,
        });
        if (!error || (error && error?.message !== "canceled")) {
          setSearching(false);
        }
      } else if (paginationCall.current && checkSearchKey(searchKey)) {
        setSearching(true);
        const error = fetchUsers({
          abortController,
          searchParams: searchKey,
          pageValue: page,
        });
        if (!error || (error && error?.message !== "canceled"))
          setSearching(false);
      } else if (paginationCall.current || !checkSearchKey(searchKey)) {
        //searchKey is not null this Will do search also for the given key with and pagination on it.
        setSearching(true);
        const error = fetchUsers({
          abortController,
          searchParams: searchKey,
          pageValue: page,
        });
        if (!error || (error && error?.message !== "canceled"))
          setSearching(false);
      }
    })();
  }, [searchKey, fetchUsers, reload, page]);

  //for opening add user form
  const handleClickOpen = () => {
    setOpen(true);
  };
  //for closing the add user form
  const handleClose = () => setOpen(false);

  const createConsumer = async (email, password1, firstName, lastName) => {
    setAdding(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/accounts/supervisor/consumers/`,
        data: {
          email,
          password1,
          first_name: firstName,
          last_name: lastName,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });

      setData((prevData) => {
        return {
          clients: {
            results: [...prevData.clients.results, response.data.user],
            count: prevData.clients.count,
          },
        };
      });
      notificationsHandler(
        "success",
        "User Added",
        enqueueSnackbar,
        closeSnackbar,
        "User added succesfully. An Email has been sent to the user"
      );
      handleClose();
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    setAdding(false);
  };
  return (
    <>
      {loadingData || error ? (
        <ComponentLoader
          loading={loadingData}
          error={error}
          minHeight="calc(100vh - 120px)"
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
          sx={{ background: "transparent" }}
        />
      ) : (
        <Grid container spacing={1}>
          {/* START: Body Row */}
          <Grid item xs={12}>
            <Grid container spacing={5}>
              {/* START: Up Side */}
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <SearchHeader
                    buttonTitle="Add New User"
                    searchBarPlaceholder="Search By Name, Email"
                    handleClick={handleClickOpen}
                    setSearchKey={setSearchKey}
                    showDateFilter={false}
                    filterByMenu={filterByMenu}
                    standardUsersPage={true} //experimental. Will be removed in upcoming versions.
                  />
                  <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    sx={{
                      "& .MuiPaper-root": {
                        borderRadius: "16px",
                      },
                    }}
                    // style={{ borderRadius: "16px" }}
                  >
                    <AddConsumerForm
                      handleClose={handleClose}
                      createConsumer={createConsumer}
                      adding={adding}
                    />
                  </Dialog>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {searching || error ? (
                  <ComponentLoader
                    loading={searching}
                    error={error}
                    sx={{ background: "transparent" }}
                    retry={() => {
                      setReload((prev) => {
                        return !prev;
                      });
                    }}
                  />
                ) : (
                  <Paper elevation={0} sx={{ mb: "4rem" }}>
                    <BasicTable
                      cols={cols}
                      rows={data.clients.results}
                      loading={loadingData || searching}
                      linkKey="username"
                      linkTo="/standard-user"
                    />
                  </Paper>
                )}

                <CustomPagination
                  disabled={searching || loadingData}
                  last_page_no={Math.ceil(data.clients.count / PAGE_SIZE)}
                  limit={data.clients.results.length}
                  handlePaginationChange={(_, value) => {
                    setPage(value);
                    paginationCall.current = true;
                  }}
                />
              </Grid>

              {/* END: Up Side */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AuthHOC(StandardUsers);
