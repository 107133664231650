import { useState } from "react";
// import PropTypes from "prop-types";
import { Stack, Paper, TextField, Button } from "@mui/material";
// CONTEXT
// import { globalContext } from "../../context/GlobalContext";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function UpdatePasswordForm(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [changingPassword, setChangingPassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setChangingPassword(true);
    props.updatePassword(password1, password2, oldPassword);
    setChangingPassword(false);
  };

  return (
    <>
      <div>
        <Button
          sx={{
            color: "#2F7EC7",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Update password
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <Paper
            sx={{
              width: "100%",
              // height: "457px",
            }}
          >
            <DialogTitle
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#000000",
                my: "18px",
              }}
            >
              Update Password
            </DialogTitle>
            <DialogContent>
              <Stack direction="column" alignItems="flex-start" spacing={3}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <DialogContentText
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Old Password
                  </DialogContentText>
                  <TextField
                    inputProps={{
                      style: {
                        padding: "10px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                        fontFamily: "DM Sans",
                      },
                    }}
                    id="old-password"
                    type="password"
                    value={oldPassword}
                    onChange={(event) => setOldPassword(event.target.value)}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#F6F6F6",
                      borderColor: "#E0E0E0",
                      width: "532px",
                    }}
                  />
                </Stack>

                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <DialogContentText
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    New Password
                  </DialogContentText>
                  <TextField
                    inputProps={{
                      style: {
                        padding: "10px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                        fontFamily: "DM Sans",
                      },
                    }}
                    id="password1"
                    type="password"
                    value={password1}
                    onChange={(event) => setPassword1(event.target.value)}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#F6F6F6",
                      borderColor: "#E0E0E0",
                      width: "532px",
                    }}
                  />
                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <DialogContentText
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Confirm Password
                  </DialogContentText>
                  <TextField
                    inputProps={{
                      style: {
                        padding: "10px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                        fontFamily: "DM Sans",
                      },
                    }}
                    id="password2"
                    type="password"
                    value={password2}
                    onChange={(event) => setPassword2(event.target.value)}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#F6F6F6",
                      borderColor: "#E0E0E0",
                      width: "532px",
                    }}
                  />
                </Stack>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Button
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#959595",
                      textTransform: "none",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#2F7EC7",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: 500,
                      textTransform: "none",
                      width: "104px",
                    }}
                    variant="filled"
                    type="submit"
                    disabled={changingPassword}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </DialogContent>
          </Paper>
        </Dialog>
      </div>
    </>
  );
}

// UpdatePasswordForm.propTypes = {
//   loading: PropTypes.bool.isRequired,
//   updatePassword: PropTypes.func.isRequired,
// };
