// libraries
import { useRef, useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import clsx from "clsx";

// material UI styles
import useTheme from "@mui/styles/useTheme";
import makeStyles from "@mui/styles/makeStyles";
import { alpha } from "@mui/material/styles";

// material UI components
import Grid from "@mui/material/Grid";

// import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";

// material UI icons
import MenuIcon from "@mui/icons-material/Menu";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

// assets

import NBScoreLogo from "../assets/logo/Neurobit-Score-Logo-Transparent-2.svg";

import CoinIcon from "../assets/sidebar/Coin.svg";

//internal
import SidebarCredits from "./credits/SidebarCredits";

// utils
import urlpatterns from "../utils/urls.json";
import { genPageTitle } from "../utils/helpers";
import DropDown from "./DropDown";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listItem: {
    color: alpha(theme.palette.common.white, 0.7),
    padding: "15px 0px",
    borderLeft: "8px solid transparent",
  },
  listItemText: {
    paddingLeft: "10px",
    "&:hover": {
      color: "#c0c0c0",
    },
    color: "#ffffff",
  },
  icon: {
    marginLeft: "15px",
    width: "20px",
  },
  active: {
    color: theme.palette.common.white,
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    padding: "20px 0px",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },
  },
}));

export const drawerWidth = 300;

export default function Sidebar({ auth, dispatch, children }) {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const globalCtx = useRef();
  globalCtx.current = useContext(globalCtx);

  useEffect(() => {
    localStorage.setItem("error_p", JSON.stringify(false));
  }, []); //for error pages

  const sidebarItems = (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "space-between",
        paddingBottom: "30px",
      }}
    >
      <div className={classes.root}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "30px 24px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderBottom: "2.2px solid #4F4F4F",

              mx: "auto",
              pt: "16px",
              pb: "26px",
            }}
          >
            {/* <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "25px",
              lineHeight: "39px",
              color: "#FFFFFF",
            }}
            mt={1}
          >
            Neurobit PSG
          </Typography> */}
            <Box sx={{ width: "130px", minHeight: "65px" }}>
              <img
                src={NBScoreLogo}
                alt="Neurobit Score"
                style={{ width: "130px" }}
              />
            </Box>
          </Box>

          {/* <Box
            borderBottom="2.19868px solid #4F4F4F"
            width={"100%"}
            paddingBottom="32px"
            textAlign="center"
          >
            <Typography
              component="h2"
              sx={{
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "36px",
                color: "#ffffff",
              }}
            >
              Neurobt PSG
            </Typography>
          </Box> */}
        </Grid>
        <List component="nav">
          <ListItem
            button
            className={clsx(classes.listItem, {
              [classes.active]: location.pathname === urlpatterns.dashboard,
            })}
            component={Link}
            to={urlpatterns.dashboard}
          >
            <DashboardOutlinedIcon
              className={classes.icon}
              sx={{ color: "#ffffff", width: "20px" }}
            />

            <Typography
              className={classes.listItemText}
              sx={{ fontSize: "14px" }}
            >
              Dashboard
            </Typography>
          </ListItem>
          <ListItem
            button
            className={clsx(classes.listItem, {
              [classes.active]:
                location.pathname === urlpatterns.credits ||
                location.pathname === urlpatterns.credit_requests,
            })}
            component={Link}
            to={urlpatterns.credits}
          >
            <img src={CoinIcon} alt="credits_icon" className={classes.icon} />
            <Typography
              className={classes.listItemText}
              sx={{ fontSize: "14px" }}
            >
              Credits
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(classes.listItem, {
              [classes.active]:
                location.pathname === urlpatterns.call_histories,
            })}
            component={Link}
            to={urlpatterns.call_histories}
          >
            <UpdateOutlinedIcon
              className={classes.icon}
              sx={{ color: "#ffffff", width: "20px" }}
            />
            <Typography
              className={classes.listItemText}
              sx={{ fontSize: "14px" }}
            >
              Call Histories
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(classes.listItem, {
              [classes.active]: location.pathname === urlpatterns.users,
            })}
            component={Link}
            to={urlpatterns.users}
          >
            <PeopleOutlineOutlinedIcon
              className={classes.icon}
              sx={{ color: "#ffffff", width: "20px" }}
            />
            <Typography
              className={classes.listItemText}
              sx={{ fontSize: "14px" }}
            >
              Standard Users
            </Typography>
          </ListItem>
        </List>
      </div>

      <SidebarCredits />
    </div>
  );

  return (
    <Box sx={{ display: "flex" }} className="Sidebar-component">
      {auth.authenticated ? (
        <>
          <CssBaseline />
          <AppBar
            elevation={0}
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              px: 2,
              backgroundColor: "#f8f8f8",
              color: theme.palette.common.black,
            }}
            className="Appbar-component"
          >
            <Toolbar
              sx={{
                pt: { xs: 0, sm: 4 },
                pb: { xs: 0, sm: 3 },
                width: "100%",
                margin: "auto",
              }}
              className="Toolbar-component"
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h5"
                fontWeight="700"
                component="div"
                sx={{
                  flexGrow: 1,
                  // marginLeft: { xs: "-0.8em", sm: "1.2em" },
                  textTransform: "capitalize",
                }}
              >
                {genPageTitle(location.pathname)}
              </Typography>
              <DropDown
                firstName={auth.user.first_name}
                lastName={auth.user.last_name}
                email={auth.user.email}
                dispatch={dispatch}
              />
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            <Drawer
              variant={mobileOpen ? "temporary" : "permanent"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  zIndex: theme.zIndex.modal + 1,
                },
              }}
              sx={{
                display: {
                  xs: mobileOpen ? "block" : "none",
                  sm: mobileOpen ? "none" : "block",
                },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "300px",
                },
              }}
            >
              {sidebarItems}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              px: 5,
              py: 3,

              backgroundColor: alpha(theme.palette.common.black, 0.026),
              minHeight: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <div style={{}} className="Root-Container">
              {children}
            </div>
          </Box>
        </>
      ) : (
        <div style={{ width: "100%" }}>{children}</div>
      )}
    </Box>
  );
}
