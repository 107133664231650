//3rd party
import { useSnackbar } from "notistack";
import { Redirect, useParams } from "react-router";
//react
import { useEffect, useState, useMemo, useRef } from "react";
//internal
import ResetPasswordForm from "../components/forms/resetPasswordForm";
//hooks
import useAuth from "../hooks/useAuth";
import useAxios from "../hooks/useAxios";
//utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";

function ResetPassword() {
  const [auth] = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const abortController = useMemo(() => {
    return new AbortController();
  }, []);
  const handleSubmit = async (event, new_password1, new_password2) => {
    event.preventDefault();
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/confirm/`,
        data: { uid: id, token, new_password1, new_password2 },
      });
      const response = await axiosInstance.current({
        ...config,
        signal: abortController.signal,
      });
      setResponse(response.data);
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  });

  if (auth.authenticated) {
    return <Redirect to={urlpatterns.dashboard} />;
  }

  return (
    <div>
      {response && JSON.stringify(response)}
      <ResetPasswordForm loading={loading} handleSubmit={handleSubmit} />
    </div>
  );
}

export default ResetPassword;
