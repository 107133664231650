/////////////////MUI/////////////////////////
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

//////////////Context//////////////////
///////////Main Code////////////
export default function ServicesInfo({ servicesData }) {
  return (
    <>
      {servicesData ? (
        <Stack
          sx={{
            width: "100%",
            my: "30px",
            backgroundColor: "#ffffff",

            padding: "32px",
          }}
          direction="column"
          spacing={2}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{ borderBottom: "1px solid #EAEAEA", pb: "24px" }}
          >
            <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
              Services - Credits Info
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              View which service requires how much credits for processing
            </Typography>
          </Stack>
          {servicesData?.base_service ? (
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
              sx={{ width: "100%" }}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                }}
                // key={index}
              >
                <Stack sx={{ width: "100%" }} direction="column" spacing={3}>
                  <Stack sx={{ width: "100%" }} direction="row">
                    <Stack
                      sx={{ width: "100%" }}
                      direction="column"
                      justifyContent="center"
                      alignItems="start"
                      spacing={0.5}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#888888",
                        }}
                      >
                        Service Type
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#0F0F0F",
                        }}
                      >
                        {servicesData?.base_service?.service?.name
                          ? servicesData.base_service.service.name.toUpperCase()
                          : ""}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: "100%" }}
                      direction="column"
                      justifyContent="center"
                      alignItems="start"
                      spacing={0.5}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#888888",
                        }}
                      >
                        Credits Per Test
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#0F0F0F",
                        }}
                      >
                        {servicesData?.base_service?.price
                          ? servicesData.base_service.price
                          : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack sx={{ width: "100%" }} direction="row">
                    <Stack
                      sx={{ width: "100%" }}
                      direction="column"
                      justifyContent="center"
                      alignItems="start"
                      spacing={0.5}
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        color="#888888"
                      >
                        Service Description
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        color="#4A4A4A"
                      >
                        {servicesData?.base_service?.service?.description
                          ? servicesData.base_service.service.description
                          : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>
          ) : (
            <Typography sx={{ fontStyle: "italic", textAlign: "center" }}>
              No services assigned to this call yet.
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "200px",
            background: "#fff",
          }}
        >
          <Typography component="p">
            Service Data Not Available Available
          </Typography>
        </Stack>
      )}
    </>
  );
}

ServicesInfo.defaultProps = {
  serviceData: [],
};
