import axios from "axios";
import { useState } from "react";
import { Redirect } from "react-router";
import useAuth from "../hooks/useAuth";
import { useSnackbar } from "notistack";
//utils
import { httpErrorHandler } from "../utils/helpers";
import { axiosConfig } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//internal
import SigninForm from "../components/forms/signinForm";

export default function SignIn() {
  const [auth, dispatch] = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [auth, dispatch] = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event, email, password) => {
    event.preventDefault();
    setLoading(true);

    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/auth/login/",
        data: { email, password },
      });
      const response = await axios({
        ...config,
        withCredentials: true,
      });
      dispatch({
        type: "signin",
        payload: {
          user: response.data.user,
          accessToken: {
            token: response.data.access_token,
            expiration: response.data.access_token_expiration,
          },
          refreshToken: {
            token: response.data.refresh_token,
            expiration: response.data.refresh_token_expiration,
          },
        },
        error: null,
      });
      setLoading(false);
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     console.log("mounted");
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // });

  if (auth.authenticated) {
    return <Redirect to={urlpatterns.dashboard} />;
  }

  return (
    <>
      {/* {error && <Error message={JSON.stringify(error)} open={true} />} */}
      <SigninForm loading={loading} handleSubmit={handleSubmit} />
    </>
  );
}
