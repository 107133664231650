import { Grid, Typography, Avatar, alpha, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Paper } from "@mui/material";
import { PersonOutlined, Remove } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
    padding: "5px",
    marginRight: "10px",
  },
  arrow: {
    color: theme.palette.success.main,
    paddingTop: " 2px",
    paddingLeft: "0px",
    marginLeft: "-4px",
  },
  small: {
    color: theme.palette.success.main,
    paddingTop: " 0px",
    paddingRight: "80px",
  },
  personIcon: {
    width: "92px",
    height: "92px",
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    margin: 0,
  },

  inCircle: {
    color: theme.palette.secondary.main,
    width: "40px",
    height: "120px",
  },
  text: {
    color: theme.palette.primary.main,
  },
  textSmall: {
    color: theme.palette.primary.main,
  },
}));

export default function TotalUsers({ loading, consumerCount }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {/* START: Total Users  */}

      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "26px 37px",
          }}
        >
          <Grid container spacing={0} direction="row" alignItems="center">
            <Grid item xs={5}>
              <Avatar className={classes.personIcon}>
                <PersonOutlined className={classes.inCircle} />
              </Avatar>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                    }}
                    variant="h4"
                    align="left"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "calc(10.7px + 1vw)",
                      lineHeight: "48px",
                      fontWeight: "500",
                    }}
                  >
                    {loading ? (
                      <Remove />
                    ) : consumerCount ? (
                      consumerCount
                    ) : (
                      <Remove />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "#AAAAAA",
                    }}
                    variant="body2"
                    align="left"
                  >
                    Total Users
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* END: Total Users */}
    </>
  );
}
