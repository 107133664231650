//3rd party
import { Redirect } from "react-router";
import axios from "axios";
import { useSnackbar } from "notistack";
//react
import { useState, useEffect } from "react";
//hooks
import useAuth from "../hooks/useAuth";
//utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//internal
import SignUpForm from "../components/forms/signupForm";
import { useHistory } from "react-router-dom";

export default function SignUp() {
  const history = useHistory();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [, setResponse] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = async (event, email, password1, firstName, lastName) => {
    setLoading(true);
    event.preventDefault();
    try {
      const config = axiosConfig({
        uri: `/auth/registration/manual/`,
        method: "POST",
        data: {
          email,
          password1,
          first_name: firstName,
          last_name: lastName,
          type: "SUPERVISOR",
        },
      });
      let response = await axios({ ...config });

      setLoading(false);
      setResponse(response.data);
      history.push("/accounts/signin");
      notificationsHandler(
        "success",
        "SignUp Succesfull. Please login with the same credentials.",
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      setLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      console.log("mounted");
    }
    return () => {
      isMounted = false;
    };
  });

  if (auth.authenticated) {
    return <Redirect to={urlpatterns.dashboard} />;
  }

  return (
    <>
      {/* {error && <Error message={JSON.stringify(error)} open={true} />} */}
      <SignUpForm loading={loading} handleSubmit={handleSubmit} />
      {/* {response && <center>{JSON.stringify(response)}</center>} */}
    </>
  );
}
