import { useEffect, useState, useCallback, useRef, useMemo } from "react";
//3rd party
import { useParams, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
//utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//assets
import image5 from "../assets/image5.png";
//mui
import { Container, Grid, Paper, Typography, Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
//hooks
import useAxios from "../hooks/useAxios";

export default function VerifyEmail(props) {
  const { token } = useParams();
  const [verifying, setVerifying] = useState(true);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const abortController = useMemo(() => new AbortController(), []);
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const verifyEmail = useCallback(async () => {
    try {
      const config = axiosConfig({
        uri: `/auth/registration/manual/verify-email/`,
        method: "POST",
        data: { key: token },
      });
      const response = await axiosInstance.current({
        ...config,
        signal: abortController.signal,
      });
      if (response.status === 200) {
        setVerifying(false);
        setVerified(true);
      }
      setError("Invalid Token! Account not activated");
      setVerifying(false);
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      setVerifying(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    verifyEmail();
  }, [token, verifyEmail]);

  return (
    <div>
      {verifying ? (
        "verfying your email..."
      ) : verified ? (
        <Container
          maxWidth={false}
          style={{
            minHeight: "100vh",
            height: "100%",
            width: "100%",
            backgroundImage: `url(${image5})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container
            component="main"
            maxWidth="sm"
            sx={{ mb: 4 }}
            style={{
              paddingTop: "50px",
            }}
          >
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              style={{
                minHeight: "500px",
              }}
            >
              <Typography
                component="div"
                variant="body2"
                style={{
                  width: "100%",
                  color: "#000000",
                }}
                align="center"
              >
                <Box
                  fontWeight={600}
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="36px"
                  lineHeight="43.2px"
                >
                  Z3score
                </Box>
              </Typography>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4} sx={{ height: "70px", width: "50px" }}>
                    <Typography
                      sx={{
                        marginLeft: "30px",
                        color: "#000000",
                        marginTop: "10px",
                      }}
                    >
                      _________________
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={4} sx={{ height: "70px", width: "50px" }}>
                    <Typography
                      component="div"
                      sx={{
                        marginTop: "18px",
                        marginLeft: "45px",
                        color: "#000000",
                        width: "100%",
                      }}
                    >
                      <Box
                        fontWeight={500}
                        fontFamily="DM Sans"
                        fontStyle="normal"
                        fontSize="14px"
                      >
                        Email verified{" "}
                      </Box>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  >
                    <Typography sx={{ color: "#000000", marginTop: "10px" }}>
                      _________________
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <CheckCircleOutlineIcon
                      style={{
                        color: "#47D7A2",
                        marginLeft: "70px",
                        width: "53.33px",
                        height: "53.33px",
                      }}
                      fontSize="large"
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>
              <Toolbar>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    style={{
                      width: "100%",
                      color: "#000000",
                    }}
                    align="center"
                  >
                    <Box
                      fontWeight={400}
                      fontFamily="DM Sans"
                      fontStyle="normal"
                      fontSize="14px"
                    >
                      Your email address has been <b>verified succesfully!</b>
                    </Box>
                  </Typography>
                  <Typography
                    component="div"
                    style={{
                      width: "100%",
                      color: "#000000",
                    }}
                    align="center"
                  >
                    <Box
                      fontWeight={400}
                      fontFamily="DM Sans"
                      fontStyle="normal"
                      fontSize="14px"
                    >
                      You will be redirected to dashboard in 10 seconds.
                    </Box>
                  </Typography>
                  <Link
                    to={urlpatterns.dashboard}
                    style={{ color: "#2CA9E3" }}
                    align="center"
                  >
                    Take me there now
                  </Link>
                </Grid>
              </Toolbar>
            </Paper>
          </Container>
        </Container>
      ) : (
        error
      )}
    </div>
  );
}
