import { Redirect } from "react-router";
import useAuth from "../hooks/useAuth";
import urlpatterns from "../utils/urls.json";

export default function AuthHOC(WrappedComponent) {
  function Component(props) {
    const [auth, dispatch] = useAuth();
    if (!auth.authenticated) {
      return <Redirect to={urlpatterns.signin} />;
    }
    return <WrappedComponent user={auth.user} dispatch={dispatch} />;
  }

  return Component;
}




