//This component is modified version of  basic table component. It uses it's feature and some additional cutomisation on top of it.

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//internal
import Status from "../status/Status";
import { CircularProgress } from "@mui/material";
//utils and helper
// import { cellData } from "../../utils/helpers";
//3rd party
import { Link, useHistory } from "react-router-dom";

export const cellData = ({ cell, status, statusColorMode, ...props }) => {
  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }
  if (status) {
    return <Status status={`${cell}`} colorMode={statusColorMode} />;
  }
  if (typeof cell === "boolean") {
    return cell ? "✅" : "❌";
  }
  if (typeof cell === "number") {
    return cell;
  }
  if (cell === null) {
    return "Not performed yet";
  }
  if (isValidDate(cell)) {
    return new Date(cell).toDateString();
  }
  return cell;
};

// Sample
// const cols = [
//   {
//     name: "Credits Used",
//     accessor: "root",
//     getAccessor: (el) => {
//       return el?.message.action === "credit.deducted"
//         ? parseFloat(el?.message?.net_credits_required).toFixed(2)
//         : parseFloat(el?.message?.net_credits_refunded).toFixed(2);
//     },
//     linkTo: null,
//   },
//   {
//     name: "Call Name",
//     accessor: "root",
//     getAccessor: (el) => {
//       return el?.call?.file_name;
//     },
//     linkTo: null,
//   },
//   {
//     name: "Service Used",
//     accessor: "root",
//     getAccessor: (el) => {
//       return el?.call?.service;
//     },
//     linkTo: null,
//   },
//   {
//     name: "Time",
//     accessor: "created_on",
//     linkTo: null,
//   },
//   {
//     name: "Date",
//     accessor: "created_on",

//     linkTo: null,
//   },
//   {
//     name: "Trn. Activity",
//     accessor: "root",
//     getAccessor: (el) => {
//       return el.message.action === "credit.deducted"
//         ? "Deducted"
//         : el.message.action === "credit.refund"
//         ? "Refunded"
//         : el.message.action;
//     },
//     linkTo: null,
//   },
// ];
// const rows = {
//   count: 5,
//   results: [
//     {
//       id: 3,
//       created_on: "2022-09-23T10:04:28.299382Z",
//       message: {
//         action: "credit.deducted",
//         blocks: [
//           {
//             id: 1,
//             used: "4.0000000",
//             assigned: "40.0000000",
//             available: "36.0000000",
//             credits_deducted: "4.0000000",
//           },
//         ],
//         net_credits_required: "4.0000000",
//       },
//       credit: null,
//       call: {
//         id: 1,
//         file_name: "Test EDF",
//         credit_used: "4.0000000",
//         time_call_started: "2022-09-23T09:54:27Z",
//         time_call_ended: "2022-09-23T09:58:34Z",
//         total_time: "0:04:07",
//         dir_path: "",
//         status: "error",
//         created_on: "2022-09-23T10:02:13.773798Z",
//         is_file_uploaded: true,
//         is_active: true,
//         user: 3,
//         service: "PSG",
//         montage: 1,
//       },
//     },
//     {
//       id: 4,
//       created_on: "2022-09-23T10:05:06.548926Z",
//       message: {
//         action: "credit.refund",
//         blocks: [
//           {
//             id: 1,
//             used: "0E-7",
//             assigned: "40.0000000",
//             available: "40.0000000",
//             credits_refunded: "4.0000000",
//           },
//         ],
//         net_credits_refunded: "4.0000000",
//       },
//       credit: null,
//       call: {
//         id: 1,
//         file_name: "Test EDF",
//         credit_used: "4.0000000",
//         time_call_started: "2022-09-23T09:54:27Z",
//         time_call_ended: "2022-09-23T09:58:34Z",
//         total_time: "0:04:07",
//         dir_path: "",
//         status: "error",
//         created_on: "2022-09-23T10:02:13.773798Z",
//         is_file_uploaded: true,
//         is_active: true,
//         user: 3,
//         service: "PSG",
//         montage: 1,
//       },
//     },
//   ],
// };

function GetTableCell({
  cols,
  row,
  index,
  statusColorMode,
  decorateStatus,
  rowLink,
  ...props
}) {
  const history = useHistory();
  const handleClick = (e, col) => {
    if (col?.linkTo && typeof col.linkTo === "function") {
      history.push(col.linkTo(row), { state: row });
      return;
    } else if (Boolean(rowLink?.linkKey && rowLink.linkTo)) {
      history.push(rowLink?.linkTo + "/" + row[rowLink?.linkKey], row);
      return;
    }
  };

  return (
    <>
      {cols.map((col, index) => {
        return (
          <TableCell
            key={index}
            sx={{
              cursor:
                (col?.linkTo && typeof col?.linkTo === "function") ||
                rowLink?.linkTo
                  ? "pointer"
                  : "unset",
              fontSize: "16px",
              fontWeight: "400",
              color: "#0f0f0f",
              textAlign: decorateStatus?.includes(col.name) ? "right" : "left",
            }}
            onClick={(e) => {
              handleClick(e, col);
            }}
          >
            {col.getAccessor
              ? cellData({
                  cell:
                    col.accessor === "root"
                      ? col.getAccessor(row)
                      : col.getAccessor(row[col.accessor]),
                  status: decorateStatus?.includes(col.name),
                  statusColorMode: statusColorMode,
                })
              : decorateStatus?.includes(col.name)
              ? cellData({
                  cell: row[col.accessor],
                  status: true,
                  statusColorMode: statusColorMode,
                })
              : cellData({ cell: row[col.accessor] })}
          </TableCell>
        );
      })}
    </>
  );
}
GetTableCell.defaultProps = {
  cols: [],
  row: [],
  index: 0,
  statusColorMode: "bg",
  decorateStatus: {},
  rowLink: {},
};
function TableComponent({
  rows,
  cols,
  decorateStatus,
  statusColorMode,
  enableViewAll,
  rowLink,
  loading,
  paginate,
  sx,
  ...props
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        overflowX: "auto",
        mt: "24px",
        boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
        ...sx,
      }}
    >
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
            <TableRow>
              {cols.map((col, index) => {
                return (
                  <TableCell
                    align="left"
                    style={{
                      width: 100,
                      color: "#888888",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    key={index}
                  >
                    {col.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* circular progress to show if data is under fetching*/}
            {loading ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={cols.length} align="center">
                  <CircularProgress sx={{ color: "#2F7EC7" }} />
                </TableCell>
              </TableRow>
            ) : //else show results
            rows?.count > 0 ? (
              //if viewAll enabled and max results key found
              enableViewAll?.maxResults ? (
                rows?.results
                  .slice(0, enableViewAll?.maxResults)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <GetTableCell
                          cols={cols}
                          row={row}
                          index={index}
                          key={index}
                          decorateStatus={decorateStatus}
                          statusColorMode={statusColorMode}
                          rowLink={rowLink}
                        />
                      </TableRow>
                    );
                  })
              ) : (
                // else show all results (View All disabled)
                rows?.results.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <GetTableCell
                        cols={cols}
                        row={row}
                        index={index}
                        key={index}
                        rowLink={rowLink}
                        decorateStatus={decorateStatus}
                        statusColorMode={statusColorMode}
                      />
                    </TableRow>
                  );
                })
              )
            ) : (
              // no data available (row length 0)
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#0F0F0F",
                    textAlign: "center",
                  }}
                  colSpan={cols?.length}
                >
                  No Data Available
                </TableCell>
              </TableRow>
            )}
            {/* if not loading and viewAllenabled and rows.count > 0 then show
            viewAll at bottom*/}
            {!loading && enableViewAll?.viewAll && rows.count ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  colSpan={cols.length}
                  sx={{
                    cursor: "pointer",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "#2F7EC7",
                  }}
                >
                  <Link
                    to={enableViewAll?.viewLink ? enableViewAll?.viewLink : "#"}
                  >
                    {enableViewAll?.title ? enableViewAll?.title : "View All"}
                  </Link>
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginate}
    </Paper>
  );
}

TableComponent.defaultProps = {
  rows: [],
  cols: [
    {
      name: "Col1",
    },
    {
      name: "Col2",
    },
    {
      name: "Col3",
    },
    {
      name: "Col4",
    },
  ],
  decorateStatus: [],
  statusColorMode: "bg",
  enableViewAll: {},
  rowLink: {},
  loading: false,
  paginate: <></>,
  sx: {},
};
export default TableComponent;
