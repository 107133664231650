import { useEffect, useState, useRef } from "react";
//3rd party
import { useSnackbar } from "notistack";
import { Redirect } from "react-router";
//internal
import ForgotPasswordForm from "../components/forms/forgotPasswordForm";
//hooks
import useAxios from "../hooks/useAxios";
import useAuth from "../hooks/useAuth";
//utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";

function ForgotPassword() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const abortController = new AbortController();
  const handleSubmit = async (event, email) => {
    event.preventDefault();
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/`,
        data: { email },
      });
      const response = await axiosInstance.current({
        ...config,
        signal: abortController.signal,
      });
      setResponse(response.data);
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      console.log("mounted");
    }
    return () => {
      isMounted = false;
      abortController.abort();
    };
  });

  if (auth.authenticated) {
    return <Redirect to={urlpatterns.dashboard} />;
  }

  return (
    <div>
      {response && JSON.stringify(response)}
      <ForgotPasswordForm loading={loading} handleSubmit={handleSubmit} />
    </div>
  );
}

export default ForgotPassword;
