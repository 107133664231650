import { useState } from "react";
import logo from "../../assets/logo.svg";
import Button from "@mui/material/Button";
import { Container, Paper, Typography, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";

export default function ResetPasswordForm({ handleSubmit, loading }) {
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#E5E5E5",
        height: "100vh",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "700px",
            padding: "64px 64px  263px 64px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="column" spacing={8}>
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Reset Password{" "}
              </Typography>
              <form
                method="post"
                onSubmit={(event) =>
                  handleSubmit(event, newPassword1, newPassword2)
                }
              >
                <Stack direction="column" spacing={3}>
                  <Stack sx={{ width: "100%" }} spacing={1}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      New Password
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          padding: "15px",
                          fontSize: "16px",
                          color: "#0F0F0F",
                          fontFamily: "DM Sans",
                        },
                      }}
                      type="Password"
                      value={newPassword1}
                      onChange={(event) => setNewPassword1(event.target.value)}
                      required
                      sx={{
                        borderRadius: "5px",
                        backgroundColor: "#FBFBFB",
                        borderColor: "1px solid #C4C4C4",
                        width: "100%",
                        maxWidth: "700px",
                      }}
                    />
                  </Stack>
                  <Stack sx={{ width: "100%" }} spacing={1}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Confirm Password{" "}
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          padding: "15px",
                          fontSize: "16px",
                          color: "#0F0F0F",
                          fontFamily: "DM Sans",
                        },
                      }}
                      type="Password"
                      value={newPassword2}
                      onChange={(event) => setNewPassword2(event.target.value)}
                      required
                      sx={{
                        borderRadius: "5px",
                        backgroundColor: "#FBFBFB",
                        borderColor: "1px solid #C4C4C4",
                        width: "100%",
                        maxWidth: "700px",
                      }}
                    />
                  </Stack>

                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    sx={{
                      backgroundColor: "#2F7EC7",
                      padding: "15px",
                      borderRadius: "5px",
                      width: "100%",
                      maxWidth: "700px",
                    }}
                  >
                    RESET PASSWORD
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Stack>

          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#8A8A8A",
                textAlign: "center",
                lineHeight: "0.1em",
                width: "100%",
                marginTop: "72px",
              }}
            >
              If you need any help, contact{" "}
              <font color="#2F7EC7">Neurobit PSG Support</font>
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </Container>

    // PASSWORD RESET SUCCESSFULLY

    // <Container
    //   maxWidth={false}
    //   style={{
    //     padding: "50px",
    //     backgroundColor: "#E5E5E5",
    //     height: "100vh",
    //   }}
    // >
    //   <Stack justifyContent="center" alignItems="center">
    //     <Paper
    //       elevation={0}
    //       style={{
    //         width: "100%",
    //         maxWidth: "700px",
    //         padding: "64px 64px 140px 64px",
    //         borderRadius: "12px",
    //       }}
    //     >
    //       <Stack direction="column" spacing={4}>
    //         <Stack direction="column" spacing={8}>
    //           <Stack justifyContent="center" alignItems="center">
    //             <img src={logo} alt="logo" width={108} height={60} />
    //           </Stack>
    //           <Stack justifyContent="center" alignItems="center">
    //             <img src={logo} alt="done" width={100} height={100} />
    //           </Stack>
    //         </Stack>
    //         <Stack direction="column" spacing={4}>
    //           <Stack justifyContent="center" alignItems="center">
    //             <Typography
    //               sx={{
    //                 fontSize: "32px",
    //                 fontWeight: "500",
    //                 color: "#0F0F0F",
    //                 textAlign: "center",
    //                 lineHeight: "0.1em",
    //                 width: "100%",
    //               }}
    //             >
    //               Password Reset Successfully!
    //             </Typography>
    //           </Stack>
    //           <Stack justifyContent="center" alignItems="center">
    //             <Stack direction="column">
    //               <Typography
    //                 sx={{
    //                   fontSize: "20px",
    //                   fontWeight: "400",
    //                   color: "#8A8A8A",
    //                   textAlign: "center",
    //                   width: "100%",
    //                 }}
    //               >
    //                 Your password was reset successfully!
    //               </Typography>
    //               <Typography
    //                 sx={{
    //                   fontSize: "20px",
    //                   fontWeight: "400",
    //                   color: "#8A8A8A",
    //                   textAlign: "center",
    //                   width: "100%",
    //                 }}
    //               >
    //                 You will be redirected to the login page in 10 seconds.{" "}
    //               </Typography>
    //             </Stack>
    //           </Stack>
    //           <Stack justifyContent="center" alignItems="center">
    //             <Button
    //               variant="contained"
    //               sx={{
    //                 backgroundColor: "#2F7EC7",
    //                 padding: "10px",
    //                 borderRadius: "5px",
    //                 width: "40%",
    //               }}
    //             >
    //               LOGIN
    //             </Button>
    //           </Stack>
    //         </Stack>
    //         <Stack direction="column">
    //           <Typography
    //             sx={{
    //               fontSize: "20px",
    //               fontWeight: "400",
    //               color: "#8A8A8A",
    //               textAlign: "center",
    //               lineHeight: "0.1em",
    //               width: "100%",
    //               marginTop: "72px",
    //             }}
    //           >
    //             If you need any help, contact{" "}
    //             <font color="#2F7EC7">Neurobit PSG Support</font>
    //           </Typography>
    //         </Stack>
    //       </Stack>
    //     </Paper>
    //   </Stack>
    // </Container>
  );
}
