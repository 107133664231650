////////////MUI//////////////////
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
const getDate = (datetimestring) => {
  if (datetimestring === null) {
    return "-";
  }
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date_ = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datetimestring)
  );
  return date_;
};

function getValue(row, key) {
  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }
  if (typeof row[key] === "string" && !isValidDate(row[key])) {
    return row[key];
  } else if (typeof row[key] === "boolean") {
    return row[key].toString();
  } else if (typeof row[key] === "number") {
    return row[key];
  } else if (row[key] === null || row[key] === "" || row[key] === undefined) {
    return "-";
  } else if (typeof row[key] === "function") {
    return row[key]();
  }
  if (isValidDate(row[key])) {
    return getDate(row[key]);
  }
}
///////////////////Sample data format it can accept/////////
// Note - spacing will apply to stack
//Array of arrays
// const useData = [
//   [
//     { first: "hello" },
//     {
//       stackStyle: {
//         border: "1px solid black",
//       },
//       typographyStyle: {
//         keyStyle: { fontSize: "2rem" },
//         valueStyle: { color: "red" },
//       },
//     },
//   ],
//   [{ last: "world", las: "asldkfajsd" }],
//   [
//     {
//       full: () => {
//         return "hello world";
//       },
//     },
//   ],
//   [{ isActive: true }],
//   [{ etc: 10 }],
//   [{ date: new Date().toString() }],
//   [{ adfadf: "akjsdlkfajsdlkfjalkdsf" }],
// ];
const customRow = (row, index) => {
  for (const mainKey in row[0]) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={5.5}
        key={index}
        sx={(theme) => {
          return row.length > 1 ? { ...row[1].typographyStyle } : {};
        }}
      >
        <Typography
          fontSize="16px"
          fontWeight="400"
          color="#898989"
          lineHeight="21px"
          sx={(theme) => {
            return row.length > 1
              ? row[1].typographyStyle
                ? {
                    minWidth: "100px",
                    ...row[1].typographyStyle.keyStyle,
                  }
                : { minWidth: "100px" }
              : { minWidth: "100px" };
          }}
        >
          {mainKey}
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight="500"
          color="#0F0F0F"
          sx={(theme) => {
            return row.length > 1
              ? row[1].typographyStyle
                ? { ...row[1].typographyStyle.valueStyle }
                : {}
              : {};
          }}
        >
          {getValue(row[0], mainKey)}
        </Typography>
      </Stack>
    );
  }
};

export default function UserInfo({
  data,
  tagLine,
  title,
  actionCallback,
  actionTitle,
  actionStyles,
  disableAction,
  consent,
  open,
  setOpen,
  ...props
}) {
  const handleConsent = () => {
    setOpen(true);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          padding: "32px",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ borderBottom: " 1px solid #EAEAEA", pb: "8px" }}
        >
          <Box>
            <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
              {title}
            </Typography>
            <Typography fontSize="14px" fontWeight="500" color="#898989">
              {tagLine}
            </Typography>
          </Box>

          {!disableAction && (
            <Button
              disableElevation
              variant="contained"
              onClick={consent ? handleConsent : actionCallback}
              sx={{
                padding: "12px 20px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",

                ...actionStyles,
              }}
            >
              {actionTitle}
            </Button>
          )}
        </Stack>

        <Stack direction="column" spacing={2} sx={{ my: "28px" }}>
          {data && data.length ? (
            data.map((row, index) => {
              return customRow(row, index);
            })
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
      {consent}
    </>
  );
}

UserInfo.defaultProps = {
  data: [],
  title: "Title",
  tagLine: "",
  actionCallback: () => {},
  actionTitle: "Action",
  actionStyles: {},
  consent: null,
};
